.container {
    padding: 3rem;
}

.main {
}

.title {
    font-size: 10rem;
    font-weight: normal;
    margin-top: -5rem;
}

.fieldset {
    font-size: 2rem;
    background-color: #F4F4F4;
    border-radius: 6px;

    > legend {
        font-weight: bold;
        padding: 0 0.8rem;
        background-color: white;
    }
}

.inputs {
    // display: flex;
}

.input {
    display: block;
    width: 100% !important;
    min-height: 7em !important;
    margin: 0.75rem 0 0 !important;
    padding: 0.75rem;
    font-family: monospace;
    font-size: 2rem;
    border: 1px solid #888;
    border-radius: 4px;
}

.submit {
    display: block;
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    border: 1px solid #888;
    border-radius: 4px;
    background: white;
    cursor: pointer;
}

.results {
    margin-top: 3rem;
}

.headline {
    margin: 2rem 0 1rem;
}

.table {
    font-family: monospace;
    font-size: 2.2rem;
    font-weight: 500;
}

.tr {

}

.td {
    width: 2.3rem;
}
